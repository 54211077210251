require('./styles/index.scss');

import AudioRecorder from './audio-recorder';

$(document).ready(function () {
    $("#mic_button_a,#mic_button_b,#mic_button_title_a,#mic_button_title_b").click(function () {
        new AudioRecorder({
            clickedButton: $(this),
            maxTime: 30,
            popup: $("#popup_audio"),
            lightbox: $("#transbox")
        })
    })
})
