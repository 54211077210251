import JSZip from 'jszip';
import Recorder from './recorder-src';

const recordingIcon = require('@/assets/circle.svg');
const stopIcon = require('@/assets/square.svg');
const playIcon = require('@/assets/play.svg');

import createPopup from './popup';

export default class ScribjabAudioRecorder {
    constructor({ clickedButton, maxTime, popup, lightbox }) {
        this.maxTime = maxTime
        this.popup = createPopup(popup); //Append popup markup
        this.lightbox = lightbox; // Transparent lightbox
        this.clickedButton = clickedButton;

        //webkitURL is deprecated but nevertheless
        this.URL = window.URL || window.webkitURL;

        this.gumStream = null; 						//stream from getUserMedia()
        this.rec = null; 							//Recorder.js object
        this.input = null; 							//MediaStreamAudioSourceNode we'll be recording

        // shim for AudioContext when it's not avb. 
        this.AudioContext = window.AudioContext || window.webkitAudioContext;
        this.audioContext = null //audio context to help us record
        this.playSource = null;
        this.playStartedAt = 0; // Time that play started at
        this.recordingLength = 0; // Store recording length

        this.timeLimitTimer = null;
        this.progressInterval = null;

        this.currentRecordingBuffer = null;

        this.currentRecordingZipBase64 = null;

        // console.log("clicked")

        // Elements
        this.recordButton = document.getElementById("recorder--record-button");
        this.cancelButton = document.getElementById("recorder--cancel-button");
        this.playButton = document.getElementById("recorder--play-button");
        this.saveButton = document.getElementById("recorder--save-button");

        this.recordLabel = document.getElementById("recorder--record-label");
        this.cancelLabel = document.getElementById("recorder--cancel-label");
        this.playLabel = document.getElementById("recorder--play-label");
        this.saveLabel = document.getElementById("recorder--save-label");

        this.currentTimeLabel = document.getElementById("recorder--time");
        this.progressBar = document.getElementById("recorder--progress-bar");

        //add events to buttons
        this.recordButton.addEventListener("click", () => this.onRecordButtonClick());
        this.cancelButton.addEventListener("click", () => this.close());
        this.playButton.addEventListener("click", () => this.onPlayButtonClick());
        this.saveButton.addEventListener("click", () => this.saveRecording());

        //Button / Recording State
        this.recording = false;
        this.playing = false;

        this.setButtonPlaying(false);
        this.setButtonRecording(false);

        this.playButton.disabled = true;


        // Show popup
        this.popup.removeClass("hidden");
        this.lightbox.removeClass("hidden");
    }

    onPlayButtonClick() {
        if (this.playing) this.stopPlaying();
        else this.playRecording();
    }
    onRecordButtonClick() {
        if (this.recording) this.stopRecording();
        else this.startRecording();
    }

    startRecording() {
        // console.log("recordButton clicked");
        this.setButtonRecording(true);

        // Reset recording global variables
        this.currentRecordingZipBase64 = null;
        this.currentRecordingBuffer = null;
        this.recordingLength = 0;
        if (this.timeLimitTimer) clearTimeout(this.timeLimitTimer);

        /*
            Simple constraints object, for more advanced audio features see
            https://addpipe.com/blog/audio-constraints-getusermedia/
        */

        var constraints = { audio: true, video: false }

        /*
            We're using the standard promise based getUserMedia() 
            https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
        */

        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
            // console.log("getUserMedia() success, stream created, initializing Recorder.js ...");

            /*
                create an audio context after getUserMedia is called
                sampleRate might change after getUserMedia is called, like it does on macOS when recording through AirPods
                the sampleRate defaults to the one set in your OS for your playback device
    
            */
            this.audioContext = new this.AudioContext();

            /*  assign to gumStream for later use  */
            this.gumStream = stream;

            /* use the stream */
            this.input = this.audioContext.createMediaStreamSource(stream);

            /* 
                Create the Recorder object and configure to record mono sound (1 channel)
                Recording 2 channels  will double the file size
            */
            this.rec = new Recorder(this.input, { numChannels: 1 })

            //start the recording process
            this.rec.record()

            //Set timer that stops recording after maxtime
            this.timeLimitTimer = setTimeout(() => { this.stopRecording(); }, this.maxTime * 1000);

            this.progressInterval = setInterval(() => this.updatePlayhead(), 100);


            // console.log("Recording started");
            // console.log(this.rec);

        }).catch((err) => {
            //enable the record button if getUserMedia() fails
            // this.recordButton.disabled = false;
            // this.stopButton.disabled = true;
            this.setButtonRecording(false);
            // pauseButton.disabled = true

            // console.error(err);
        });
    }

    stopRecording() {
        // console.log("stopButton clicked");
        clearTimeout(this.timeLimitTimer);
        clearInterval(this.progressInterval);

        this.setButtonRecording(false);

        //tell the recorder to stop the recording
        this.rec.stop();

        //stop microphone access
        this.gumStream.getAudioTracks()[0].stop();

        //create the wav blob and pass it on to createDownloadLink
        this.rec.exportWAV((blob) => this.exportWAV(blob));

        // Save buffers to global variable
        this.rec.getBuffer((buffers) => this.updateAudioSource(buffers));

        // Save audio length
        this.recordingLength = this.rec.context.currentTime;
    }

    playRecording() {
        if (this.playSource) this.stopPlaying();
        this.setButtonPlaying(true);

        this.playSource = this.audioContext.createBufferSource();
        this.playSource.onended = () => this.stopPlaying();
        this.playSource.buffer = this.currentRecordingBuffer;

        this.playSource.connect(this.audioContext.destination);
        this.playSource.start(0);

        this.playStartedAt = this.playSource.context.currentTime;

        this.progressInterval = setInterval(() => this.updatePlaybackPlayhead(), 100);
    }

    stopPlaying() {
        this.setButtonPlaying(false);
        this.playSource.stop();
        clearInterval(this.progressInterval);
        this.renderPlayhead(this.recordingLength);
    }

    updateAudioSource(buffers) {
        // currentRecordingBuffer = audioContext.createBufferSource();
        this.currentRecordingBuffer = this.audioContext.createBuffer(1, buffers[0].length, this.audioContext.sampleRate);
        this.currentRecordingBuffer.getChannelData(0).set(buffers[0]);
    }

    updatePlayhead() {
        let time = this.rec.context.currentTime;
        this.renderPlayhead(time);
    }

    updatePlaybackPlayhead() {
        let time = this.playSource.context.currentTime - this.playStartedAt;
        this.renderPlayhead(time);
    }

    renderPlayhead(time) {
        this.currentTimeLabel.innerHTML = `${this.toMMSS(Math.round(time))} / ${this.toMMSS(this.maxTime)}`
        this.progressBar.style.width = `${(time / this.maxTime) * 100}%`
    }

    exportWAV(blob) {

        var zip = new JSZip();

        zip.file("recording.wav", blob); //here you have to give blobFile in the form of raw bits >> convert it in json notation.. or stream .. 

        zip.generateAsync({ type: "base64", compression: "DEFLATE" })
            .then(content => {
                console.log({ base64zipAudio: content })
                this.currentRecordingZipBase64 = content;
                this.saveButton.disabled = false;
            });
    }

    saveRecording() {
        // if (_hasSound) {

        var audioDataInput = this.clickedButton.parent().find(".audio_data_input");
        audioDataInput.val(this.currentRecordingZipBase64);
        audioDataInput.triggerHandler('change');
        // }

        this.close();
    }

    close() {
        // Clear popup and hide
        this.popup.empty();
        this.popup.addClass("hidden");
        this.lightbox.addClass("hidden");
    }

    setButtonRecording(isRecording) {
        if (isRecording) {
            this.playButton.disabled = true;
            this.recordButton.innerHTML = stopIcon;
            this.recordLabel.textContent = 'Stop'
        }
        else {
            this.playButton.disabled = false;
            this.recordButton.innerHTML = recordingIcon;
            this.recordLabel.textContent = 'Record'
        }

        this.recording = isRecording;
    }
    setButtonPlaying(isPlaying) {
        if (isPlaying) {
            this.recordButton.disabled = true;
            this.playButton.innerHTML = stopIcon;
            this.playLabel.textContent = 'Stop'
        }
        else {
            this.recordButton.disabled = false;
            this.playButton.innerHTML = playIcon;
            this.playLabel.textContent = 'Play'
        }

        this.playing = isPlaying;
    }

    toMMSS(secs) {
        var sec_num = parseInt(secs, 10)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60

        return [0, minutes, seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v, i) => v !== "00" || i > 0)
            .join(":")
    }
}