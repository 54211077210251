export default function (popupParent) {
    popupParent.addClass("recorder--popup");
    popupParent.html('');
    popupParent.append(
        `<div id="recorder--status">
            <div id="recorder--time">00:00 / 00:30</div>
            <div id="recorder--progress">
                <div id="recorder--progress-bar"></div>
            </div>
        </div>
        <div id="recorder--buttons">
            <button id="recorder--record-button" title="Record audio"></button>
            <button id="recorder--play-button" disabled title="Play audio"></button>
            <button id="recorder--cancel-button" title="Close audio recorder">${require('@/assets/x.svg')}</button>
            <button id="recorder--save-button" disabled title="Save recording">${require('@/assets/check.svg')}</button>
        </div>
        <div id="recorder--labels">
            <label id="recorder--record-label">Record</label>
            <label id="recorder--play-label">Play</label>
            <label id="recorder--cancel-label">Cancel</label>
            <label id="recorder--save-label">Save</label>
        </div>`);
    return popupParent;
}
